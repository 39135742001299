import { Box, Container, Typography } from '@mui/material'
import { useContext } from 'react'
import { LanguageContext } from '../App'
import { ContactCard } from '../components/ContactCard'
import { WeChatCard } from '../components/WeChatCard'
import BookAppointmentButton from '../components/AppointmentButton'

const ContactItem = ({ icon, text, text2 }) => {
  return (
    <Box display={'flex'} alignItems={'center'} my={2}>
      {icon}
      <Typography variant="h7">
        {text} <br /> {text2}
      </Typography>
    </Box>
  )
}
export const ContactPage = () => {
  const { siteData } = useContext(LanguageContext)
  return (
    <Box>
      <Container maxWidth={'md'}>
        <Typography
          variant="h3"
          textAlign={'center'}
          fontWeight={700}
          margin={5}
        >
          Come find us!
        </Typography>
        <Typography
          variant="h5"
          textAlign={'center'}
          fontWeight={700}
          margin={2}
        >
          {siteData.contactSection.text}
        </Typography>
        <Typography
          variant="h5"
          textAlign={'center'}
          fontWeight={700}
          margin={2}
        >
          {siteData.contactSection.text2}
        </Typography>
        {/* <Box display={'flex'} justifyContent={'center'} my={5}><BookAppointmentButton /></Box> */}
        <ContactCard
          hoursTitle={siteData.contactSection.contactItems.hours.title}
          workDays={siteData.contactSection.contactItems.hours.workDays}
          workHours={siteData.contactSection.contactItems.hours.workHours}
          street={siteData.contactSection.contactItems.address.street}
          cityStateZip={
            siteData.contactSection.contactItems.address.cityStateZip
          }
          email={siteData.contactSection.contactItems.email.content}
          phone={siteData.contactSection.contactItems.phone}
          wechat={siteData.contactSection.contactItems.wechat.content}
        />

        <WeChatCard />
      </Container>
    </Box>
  )
}
